<template>
  <div style="text-align: center">
    <h1>NOT FOUND!!!</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>